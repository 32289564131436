/**
 * --------------------------------------------
 * eMailChef SiteSearch.js
 * License MIT
 * --------------------------------------------
 */

import {lang, translate} from "./I18n";

const Dashboard = (($) => {


    const Selector = {
        TABLE: '#list_accounts',
        REGISTER_ACCESS_BUTTON: '.registerAccess',
    };

    const Api = {
        GET: basePath+'/api/contacts',
        PUT: basePath+'/api/contacts/:id'
    };

    let tableAjax = false;

    if ($(Selector.TABLE).length > 0) {
        tableAjax = $(Selector.TABLE).DataTable({
            "ajax": {
                url: Api.GET,
                "dataSrc": function (json) {
                    return json.map(_json => [
                        _json.email,
                        _json.first_name,
                        _json.last_name,
                        _json.numero_ordine,
                        _json.accesso_mostra,
                        _json.id,
                        _json.orario_di_ingresso
                    ]);
                }
            },
            "columnDefs": [
                {
                    "render": function (data, type, row) {
                        if (typeof data !== 'undefined' && data !== ''){
                            const date_used = moment(data).format('DD/MM/YYYY');
                            return `
                                <p class="text-center text-bold text-success">${translate('used')}</p>
                                <p class="text-center"><span class="fa fa-clock"></span> ${date_used} ${row[6]} </p>
                            `;
                        }

                        return `<p class="text-center"><button data-id="${row[5]}" class="btn btn-primary registerAccess">${translate('register_access')}</button></p>`;
                    },
                    "targets": 4
                },
                {
                    "render": function (data, type, row) {
                        if (typeof data !== 'undefined' && data !== ''){
                            return '<p class="text-center"><a class="btn btn-outline btn-outline-primary" href="'+storeUrl+'/wp-admin/post.php?post='+data+'&action=edit" target="_blank">Ordine '+data+'</a></p>'
                        }
                        return '<p class="text-center text-muted">- Ordine non inserito -</span></p>'
                    },
                    "targets": 3
                },
                {
                    "render": function (data, type, row) {

                        const url = `https://app.emailchef.com/build/#/contacts/list/${listId}/contact/${row[5]}/edit`;

                        return `
                            <a href="${url}" target="_blank">${data}</a>
                        `
                    },
                    "targets": 0
                }
            ],
            "language": {
                "url": `./dist/i18n/datatable/${lang()}.json`
            }
        });
    }

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true,
        focusConfirm: true,
        reverseButtons: true,
        showCloseButton: false,
        showCancelButton: false
    });

    const updateContact = (contactId) => {
        let dt = new Date();

        let hh =  dt.getHours();
        if (hh < 10) {
            hh = "0" + hh;
        }
        let mm =  dt.getMinutes();
        if (mm < 10) {
            mm = "0" + mm;
        }

        const myUpdateData = {
            accesso_mostra: dt.getFullYear() + "/" + (dt.getMonth() + 1) + "/" + dt.getDate(),
            orario_di_ingresso: hh + ":" + mm
        };

        const request = $.ajax({
            method: 'PUT',
            contentType: 'application/json',
            url: Api.PUT.replace(":id", contactId),
            data: JSON.stringify(myUpdateData)
        });

        request.done((msg) => {
            tableAjax && tableAjax.ajax.reload();
            swalWithBootstrapButtons.fire(
                translate('success'),
                translate('access_registered'),
                'success'
            );
        });
        request.fail(function (jqXHR, textStatus) {
            swalWithBootstrapButtons.fire(
                translate('error'),
                translate('access_not_registered'),
                'error'
            )
        });
    };

    $(document).on("click", Selector.REGISTER_ACCESS_BUTTON, function () {
        const contactId = $(this).data("id");

        swalWithBootstrapButtons.fire({
            title: `<strong>${translate('are_you_sure')}</strong>`,
            type: 'info',
            html: translate('want_register_access'),
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText:
                `<i class="fa fa-thumbs-up"></i> ${translate('yes')}`,
            confirmButtonAriaLabel: translate('yes'),
            cancelButtonText:
               `<i class="fa fa-thumbs-down"></i> ${translate('no')}`,
            cancelButtonAriaLabel: translate('no')
        }).then((result) => {
            if (result.value) {
                updateContact(contactId);
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    translate('cancelled'),
                    translate('operation_reverted'),
                    'error'
                )
            }
        });


    })

    return {};

})(jQuery);

export default Dashboard
