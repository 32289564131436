/**
 * --------------------------------------------
 * eMailChef SiteSearch.js
 * License MIT
 * --------------------------------------------
 */

import {translate} from './I18n';

const AdminLogin = (($) => {

    const Selector = {
        LOGIN_BUTTON  : '.login-box button',
        FORM: '#loginForm',
        LOGOUT_TRIGGER : '.do-logout',
        FIELDS: {
            USERNAME: '#username',
            PASSWORD: '#password'
        }
    };

    const Api = {
        DASHBOARD: basePath,
        LOGOUT: basePath+'/api/logout',
        LOGIN: basePath+"/login"
    };

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true,
        focusConfirm: true,
        reverseButtons: true,
        showCloseButton: false,
        showCancelButton: false
    });

    const doLogout = () => {
        const request = $.ajax({
            method: "POST",
            url: Api.LOGOUT,
        });
        request.done((msg) => {
            location.href = Api.LOGIN;
        });
        request.fail(function( jqXHR, textStatus ) {
            location.href = Api.LOGIN;
        });
    };

    $(Selector.FORM).on("submit", (evt) => {
        evt.preventDefault();
        const formActionUrl = $(Selector.FORM).attr("action");
        const request = $.ajax({
            method: "POST",
            url: formActionUrl,
            contentType: 'application/json',
            data: JSON.stringify({
                username: $(Selector.FIELDS.USERNAME).val(),
                password: $(Selector.FIELDS.PASSWORD).val()
            })
        });
        request.done((msg) => {
            location.href = Api.DASHBOARD;
        });
        request.fail(function( jqXHR, textStatus ) {
            toastr.error(translate('account_data_wrong'));
        });
    });

    $(Selector.LOGOUT_TRIGGER).on("click", (evt) => {
        swalWithBootstrapButtons.fire({
            title: `<strong>${translate('are_you_sure')}</strong>`,
            type: 'info',
            html: `${translate('want_logout')}`,
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: `<i class="fa fa-thumbs-up"></i> ${translate('yes')}`,
            confirmButtonAriaLabel: translate('yes'),
            cancelButtonText:
                `<i class="fa fa-thumbs-down"></i> ${translate('no')}`,
            cancelButtonAriaLabel: translate('no'),
        }).then((result) => {
            if (result.value) {
                doLogout();
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    translate('cancelled'),
                    translate('operation_reverted'),
                    'error'
                )
            }
        });
    });

})(jQuery);

export default AdminLogin
