/**
 * --------------------------------------------
 * AdminLTE I18n.js
 * License MIT
 * --------------------------------------------
 */

export const translate = (key) => {
    if (typeof i18n["current"][key] !== 'undefined') {
        return i18n["current"][key];
    }
    if (typeof i18n["default"][key] !== 'undefined') {
        return i18n["default"][key];
    }
    return key;
};

export const lang = () => {
    return i18n["current_code"];
};
